import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import {
  clearReviewCount,
  loadStats,
  loadStatsSuccess,
  replaceCounter,
  sampleCounterActionError,
  updateStatsOnReviewMode,
  updateStats,
  updateReviewCount,
  loadStatsForMosaicReview,
  labelTrackingStatsFetched,
} from "./sample-counters.actions";
import { AnalysisUtils, CloudFunctions } from "@telespot/sdk";
import {
  map,
  switchMap,
  catchError,
  mergeMap,
  withLatestFrom,
  filter,
} from "rxjs/operators";
import { from, EMPTY, of, defer } from "rxjs";
import {
  labelCountIds,
  labelCountReviewIsEmpty,
} from "./sample-counters.selectors";
import {
  Mode,
  ROI,
  analysisSynced,
  createAnalysisFromROIs,
  createAnalysisFromVideoROI,
  createFindingsFromROIs,
  createFindingsFromVideoROI,
  discardAllChanges,
  getUniqueLabels,
  removeROIs,
  removeVideoROIs,
  selectMode,
  setROIs,
  setReviewCounters,
  setVideoROIs,
  switchMode,
  updateROI,
  updateROILabels,
} from "../analysis";
import { analysisState, mosaicMode, selectActiveSample } from "../../+state";
import {
  extractSingleAndMultiCount,
  getOptionsCount,
  makeCountNegative,
  updateSingleMultiCount,
} from "./sample-counters.reducer";
import {
  selectHasSegmentationTasks,
  selectLabel,
  selectMultipleSelection,
} from "../protocol";
import {
  analystToExtractCrops,
  selectActiveAssetContextAnalysis,
  selectCurrentAnalyst,
  selectSelectedROIS,
  selectSelectedVideoRois,
} from "../interfeature.selectors";
import { AnalysisReviewService } from "../../services/analysis-review/analysis-review.service";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

@Injectable()
export class SampleCounterEffects {
  constructor(
    private actions$: Actions,
    private _analysisReviewService: AnalysisReviewService,
    private store$: Store
  ) {}

  loadStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStats),
      withLatestFrom(
        this.store$.select(labelCountIds),
        this.store$.select(analysisState),
        this.store$.select(mosaicMode)
      ),
      filter(
        ([action, labelCountIds, analysisState, mosaicMode]) => !mosaicMode
      ),
      switchMap(
        ([
          { sample, createdBy, analysisStateId },
          labelCountIds,
          analysisState,
        ]) => {
          if ((labelCountIds as string[])?.includes(analysisStateId))
            return EMPTY;
          return from(
            CloudFunctions.GetSampleAnalsysStats(sample, createdBy.toPointer())
          ).pipe(
            map((stats) => {
              return loadStatsSuccess({
                labelCount: stats.labelCount,
                totalCount: stats.total,
                multiLabelCount: stats.multiLabelCount,
                singleLabelCount: stats.singleLabelCount,
                analysisStateId: analysisState.id,
              });
            }),
            catchError((error) =>
              of(
                sampleCounterActionError({
                  error: `[loadStats]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  loadStatsForMosaicReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStatsForMosaicReview),
      withLatestFrom(
        this.store$.select(selectActiveSample),
        this.store$.select(analysisState),
        this.store$.select(analystToExtractCrops)
      ),
      switchMap(([_, sample, analysisState, createdBy]) => {
        return from(
          CloudFunctions.GetSampleAnalsysStats(sample, createdBy)
        ).pipe(
          map((stats) => {
            return loadStatsSuccess({
              labelCount: stats.labelCount,
              totalCount: stats.total,
              multiLabelCount: stats.multiLabelCount,
              singleLabelCount: stats.singleLabelCount,
              analysisStateId: analysisState.id,
            });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[loadStatsForMosaicReview$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  loadStatsForLabelTracking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStatsSuccess),
      withLatestFrom(
        this.store$.select(selectActiveSample),
        this.store$.select(analysisState),
        this.store$.select(analystToExtractCrops)
      ),
      switchMap(([_, sample, analysisState, createdBy]) => {
        return from(
          CloudFunctions.GetSampleAnalsysStats(sample, createdBy)
        ).pipe(
          map((stats) => {
            return labelTrackingStatsFetched({
              labelCount: stats.labelCount,
              totalCount: stats.total,
              multiLabelCount: stats.multiLabelCount,
              singleLabelCount: stats.singleLabelCount,
              analysisStateId: analysisState.id,
            });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[loadStatsForLabelTracking$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  discardChangesInMosaic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(discardAllChanges),
      withLatestFrom(
        this.store$.select(selectActiveSample),
        this.store$.select(analysisState),
        this.store$.select(selectCurrentAnalyst),
        this.store$.select(mosaicMode)
      ),
      switchMap(([_, sample, analysisState, createdBy, mosaicMode]) => {
        if (!mosaicMode) return EMPTY;
        return from(
          CloudFunctions.GetSampleAnalsysStats(sample, createdBy)
        ).pipe(
          map((stats) => {
            return loadStatsSuccess({
              labelCount: stats.labelCount,
              totalCount: stats.total,
              multiLabelCount: stats.multiLabelCount,
              singleLabelCount: stats.singleLabelCount,
              analysisStateId: analysisState.id,
            });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[discardChangesInMosaic$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnSetROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setROIs),
      withLatestFrom(
        this.store$.select(selectMode),
        this.store$.select(analysisState)
      ),
      switchMap(([{ rois }, mode, analysisState]) => {
        const roiLabels = rois
          ? AnalysisUtils.flatten(
              rois.map((roi) =>
                roi.labels.reduce(
                  (acc, currLabel) => [
                    ...acc,
                    ...Object.keys(currLabel.labels),
                  ],
                  []
                )
              )
            )
          : [];
        const singleAndMultiCount = extractSingleAndMultiCount(rois);
        const roisToUpdate = rois?.length ?? 0;
        const optionsCount = getOptionsCount(roiLabels);

        if (!optionsCount) return EMPTY;

        return of({ optionsCount, roisToUpdate, mode, analysisState }).pipe(
          map(({ optionsCount, roisToUpdate, mode }) => {
            return mode === Mode.REVIEW
              ? updateStatsOnReviewMode({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                })
              : updateStats({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                  analysisStateId: analysisState?.id,
                });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[updateStatsOnSetROIs$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnSetVideoROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setVideoROIs),
      withLatestFrom(
        this.store$.select(selectMode),
        this.store$.select(analysisState)
      ),
      switchMap(([{ rois }, mode, analysisState]) => {
        const roiLabels = rois
          ? AnalysisUtils.flatten(rois.map((roi) => Object.keys(roi.labels)))
          : [];
        const singleAndMultiCount = extractSingleAndMultiCount(
          rois.map((roi) => ({ ...roi, labels: [{ labels: roi.labels }] }))
        );
        const roisToUpdate = rois?.length ?? 0;
        const optionsCount = getOptionsCount(roiLabels);

        if (!optionsCount) return EMPTY;

        return of({ optionsCount, roisToUpdate, mode, analysisState }).pipe(
          map(({ optionsCount, roisToUpdate, mode }) => {
            return mode === Mode.REVIEW
              ? updateStatsOnReviewMode({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                })
              : updateStats({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                  analysisStateId: analysisState?.id,
                });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[updateStatsOnSetVideoROIs$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAnalysisFromROIs, createFindingsFromROIs),
      withLatestFrom(
        this.store$.select(selectMode),
        this.store$.select(analysisState)
      ),
      switchMap(([{ selectedLabels, rois }, mode, analysisState]) => {
        const roiLabels = (selectedLabels || []).reduce(
          (acc, label) => [...acc, label.uuid],
          []
        );
        const roisToUpdate = rois?.length ?? 0;
        const optionsCount = getOptionsCount(roiLabels);

        const singleAndMultiCount = {
          singleLabelCount:
            selectedLabels.length < 2 ? { [roiLabels[0]]: 1 } : {},
          multiLabelCount:
            selectedLabels.length >= 2
              ? { [roiLabels.sort().join("/")]: 1 }
              : {},
        };

        if (!optionsCount) return EMPTY;
        return of({ optionsCount, roisToUpdate, mode }).pipe(
          map(({ optionsCount, roisToUpdate, mode }) => {
            return mode === Mode.REVIEW
              ? updateStatsOnReviewMode({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                })
              : updateStats({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                  analysisStateId: analysisState?.id,
                });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[updateStatsOnCreate$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnCreateVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAnalysisFromVideoROI, createFindingsFromVideoROI),
      withLatestFrom(
        this.store$.select(selectMode),
        this.store$.select(analysisState)
      ),
      switchMap(([{ selectedLabels, roi }, mode, analysisState]) => {
        const roiLabels = (selectedLabels || []).reduce(
          (acc, label) => [...acc, label.uuid],
          []
        );
        const roisToUpdate = roi ? 1 : 0;
        const optionsCount = getOptionsCount(roiLabels);

        const singleAndMultiCount = {
          singleLabelCount:
            selectedLabels.length < 2 ? { [roiLabels[0]]: 1 } : {},
          multiLabelCount:
            selectedLabels.length >= 2
              ? { [roiLabels.sort().join("/")]: 1 }
              : {},
        };

        if (!optionsCount) return EMPTY;
        return of({ optionsCount, roisToUpdate, mode }).pipe(
          map(({ optionsCount, roisToUpdate, mode }) => {
            return mode === Mode.REVIEW
              ? updateStatsOnReviewMode({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                })
              : updateStats({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount,
                  analysisStateId: analysisState?.id,
                });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[updateStatsOnCreateVideo$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnRemoveROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeROIs),
      withLatestFrom(
        this.store$.select(selectMode),
        this.store$.select(analysisState)
      ),
      switchMap(([{ rois, selectedROIs }, mode, analysisState]) => {
        const roiLabels = AnalysisUtils.flatten(
          (rois ?? selectedROIs).map((roi) =>
            roi.labels.reduce(
              (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
              []
            )
          )
        );
        const roisToUpdate = -(rois || selectedROIs || []).length;

        const singleMultiCount = extractSingleAndMultiCount(
          rois ?? selectedROIs
        );
        const singleMultiCountUpdated = makeCountNegative(singleMultiCount);

        const optionsCount = roiLabels?.reduce(function (prev, cur) {
          prev[cur] = (prev[cur] || 0) - 1;
          return prev;
        }, {});

        if (!optionsCount) return EMPTY;

        return of({ optionsCount, roisToUpdate, mode }).pipe(
          map(({ optionsCount, roisToUpdate, mode }) => {
            return mode === Mode.REVIEW
              ? updateStatsOnReviewMode({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount: singleMultiCountUpdated,
                })
              : updateStats({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount: singleMultiCountUpdated,
                  analysisStateId: analysisState?.id,
                });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[updateStatsOnRemoveROIs$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnRemoveVideoROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeVideoROIs),
      withLatestFrom(
        this.store$.select(selectMode),
        this.store$.select(analysisState)
      ),
      switchMap(([{ rois, selectedROIs }, mode, analysisState]) => {
        const roiLabels = AnalysisUtils.flatten(
          (rois ?? selectedROIs).map((roi) => Object.keys(roi.labels))
        );
        const roisToUpdate = -(rois || selectedROIs || []).length;

        const singleMultiCount = extractSingleAndMultiCount(
          (rois ?? selectedROIs).map((roi) => ({
            ...roi,
            labels: [{ labels: roi.labels }],
          }))
        );
        const singleMultiCountUpdated = makeCountNegative(singleMultiCount);

        const optionsCount = roiLabels?.reduce(function (prev, cur) {
          prev[cur] = (prev[cur] || 0) - 1;
          return prev;
        }, {});

        if (!optionsCount) return EMPTY;

        return of({ optionsCount, roisToUpdate, mode }).pipe(
          map(({ optionsCount, roisToUpdate, mode }) => {
            return mode === Mode.REVIEW
              ? updateStatsOnReviewMode({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount: singleMultiCountUpdated,
                })
              : updateStats({
                  optionsCount,
                  roisToUpdate,
                  singleAndMultiCount: singleMultiCountUpdated,
                  analysisStateId: analysisState?.id,
                });
          }),
          catchError((error) =>
            of(
              sampleCounterActionError({
                error: `[updateStatsOnRemoveVideoROIs$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateStatsOnSelectLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLabel),
      withLatestFrom(
        this.store$.select(selectSelectedROIS),
        this.store$.select(selectMultipleSelection),
        this.store$.select(selectMode),
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(analysisState)
      ),
      switchMap(
        ([
          { uuid },
          roisSelected,
          multiSelect,
          mode,
          hasSegmentationTasks,
          analysisState,
        ]) => {
          if (hasSegmentationTasks || roisSelected.length === 0) return EMPTY;
          const roisToUpdate = 0;
          let optionsCount;

          const allRoiLabels = roisSelected.reduce(
            (acc, roi) => [
              ...acc,
              ...roi.labels.reduce(
                (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
                []
              ),
            ],
            []
          );

          const selectedLabel = uuid;
          const singleMultiCount = extractSingleAndMultiCount(roisSelected);
          let singleMultiCountUpdated = makeCountNegative(singleMultiCount);

          if (!multiSelect) {
            optionsCount = allRoiLabels.reduce(function (prev, cur) {
              prev[cur] = (prev[cur] || 0) - 1;
              return prev;
            }, {});

            if (Object.keys(optionsCount).includes(selectedLabel)) {
              optionsCount[selectedLabel] =
                optionsCount[selectedLabel] + roisSelected?.length;
            } else {
              optionsCount = {
                ...optionsCount,
                [selectedLabel]: roisSelected?.length,
              };
            }

            if (
              Object.keys(singleMultiCountUpdated.singleLabelCount).includes(
                selectedLabel
              )
            ) {
              singleMultiCountUpdated.singleLabelCount[selectedLabel] =
                singleMultiCountUpdated.singleLabelCount[selectedLabel] +
                roisSelected?.length;
            } else {
              singleMultiCountUpdated = {
                singleLabelCount: {
                  ...singleMultiCountUpdated.singleLabelCount,
                  [selectedLabel]: roisSelected?.length,
                },
                multiLabelCount: singleMultiCountUpdated.multiLabelCount,
              };
            }
          } else {
            optionsCount = {
              [selectedLabel]: allRoiLabels.includes(selectedLabel)
                ? -roisSelected?.length
                : roisSelected?.length,
            };

            const newMultiLabels = [];
            const newSingleLabels = [];

            roisSelected.map((roi) => {
              let newLabels = [];
              const roiLabels = roi.labels.reduce(
                (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
                []
              );

              if (roiLabels.includes(selectedLabel)) {
                newLabels = roiLabels.filter((item) => item !== selectedLabel);
              } else {
                newLabels = [...roiLabels, selectedLabel];
              }
              if (newLabels.length === 0) return;
              if (newLabels.length === 1) {
                newSingleLabels.push(...newLabels);
              }
              newMultiLabels.push(newLabels.sort().join("/"));
            });

            const newLabelsMultiCount = getOptionsCount(newMultiLabels);
            const newLabelsSingleCount = getOptionsCount(newSingleLabels);

            singleMultiCountUpdated = {
              singleLabelCount: {
                ...singleMultiCountUpdated.singleLabelCount,
                ...Object.keys(newLabelsSingleCount).reduce((acc, label) => {
                  if (singleMultiCountUpdated.multiLabelCount[label]) {
                    acc[label] =
                      singleMultiCountUpdated.multiLabelCount[label] +
                      newLabelsSingleCount[label];
                  } else {
                    acc[label] = newLabelsSingleCount[label];
                  }
                  return acc;
                }, {}),
              },
              multiLabelCount: {
                ...singleMultiCountUpdated.multiLabelCount,
                ...Object.keys(newLabelsMultiCount).reduce((acc, label) => {
                  if (singleMultiCountUpdated.multiLabelCount[label]) {
                    acc[label] =
                      singleMultiCountUpdated.multiLabelCount[label] +
                      newLabelsMultiCount[label];
                  } else {
                    acc[label] = newLabelsMultiCount[label];
                  }
                  return acc;
                }, {}),
              },
            };
          }
          if (!optionsCount) return EMPTY;

          return of({ optionsCount, roisToUpdate, mode }).pipe(
            map(({ optionsCount, roisToUpdate, mode }) => {
              return mode === Mode.REVIEW
                ? updateStatsOnReviewMode({
                    optionsCount,
                    roisToUpdate,
                    singleAndMultiCount: singleMultiCountUpdated,
                  })
                : updateStats({
                    optionsCount,
                    roisToUpdate,
                    singleAndMultiCount: singleMultiCountUpdated,
                    analysisStateId: analysisState?.id,
                  });
            }),
            catchError((error) =>
              of(
                sampleCounterActionError({
                  error: `[updateStatsOnSelectLabel$]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  updateStatsOnSelectLabelVideoRois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLabel),
      withLatestFrom(
        this.store$.select(selectSelectedVideoRois),
        this.store$.select(selectMultipleSelection),
        this.store$.select(selectMode),
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(analysisState)
      ),
      switchMap(
        ([
          { uuid },
          roisSelected,
          multiSelect,
          mode,
          hasSegmentationTasks,
          analysisState,
        ]) => {
          if (hasSegmentationTasks || roisSelected.length === 0) return EMPTY;
          const roisToUpdate = 0;
          let optionsCount;

          const allRoiLabels = AnalysisUtils.flatten(
            roisSelected.map((roi) => Object.keys(roi.labels))
          );

          const selectedLabel = uuid;
          const singleMultiCount = extractSingleAndMultiCount(
            roisSelected.map((roi) => ({
              ...roi,
              labels: [{ labels: roi.labels }],
            }))
          );
          let singleMultiCountUpdated = makeCountNegative(singleMultiCount);

          if (!multiSelect) {
            optionsCount = allRoiLabels.reduce(function (prev, cur) {
              prev[cur] = (prev[cur] || 0) - 1;
              return prev;
            }, {});

            if (Object.keys(optionsCount).includes(selectedLabel)) {
              optionsCount[selectedLabel] =
                optionsCount[selectedLabel] + roisSelected?.length;
            } else {
              optionsCount = {
                ...optionsCount,
                [selectedLabel]: roisSelected?.length,
              };
            }

            if (
              Object.keys(singleMultiCountUpdated.singleLabelCount).includes(
                selectedLabel
              )
            ) {
              singleMultiCountUpdated.singleLabelCount[selectedLabel] =
                singleMultiCountUpdated.singleLabelCount[selectedLabel] +
                roisSelected?.length;
            } else {
              singleMultiCountUpdated = {
                singleLabelCount: {
                  ...singleMultiCountUpdated.singleLabelCount,
                  [selectedLabel]: roisSelected?.length,
                },
                multiLabelCount: singleMultiCountUpdated.multiLabelCount,
              };
            }
          } else {
            optionsCount = {
              [selectedLabel]: allRoiLabels.includes(selectedLabel)
                ? -roisSelected?.length
                : roisSelected?.length,
            };

            const newMultiLabels = [];
            const newSingleLabels = [];

            roisSelected.map((roi) => {
              let newLabels = [];
              const roiLabels = AnalysisUtils.flatten(
                roisSelected.map((roi) => Object.keys(roi.labels))
              );

              if (roiLabels.includes(selectedLabel)) {
                newLabels = roiLabels.filter((item) => item !== selectedLabel);
              } else {
                newLabels = [...roiLabels, selectedLabel];
              }
              if (newLabels.length === 0) return;
              if (newLabels.length === 1) {
                newSingleLabels.push(...newLabels);
              }
              newMultiLabels.push(newLabels.sort().join("/"));
            });

            const newLabelsMultiCount = getOptionsCount(newMultiLabels);
            const newLabelsSingleCount = getOptionsCount(newSingleLabels);

            singleMultiCountUpdated = {
              singleLabelCount: {
                ...singleMultiCountUpdated.singleLabelCount,
                ...Object.keys(newLabelsSingleCount).reduce((acc, label) => {
                  if (singleMultiCountUpdated.multiLabelCount[label]) {
                    acc[label] =
                      singleMultiCountUpdated.multiLabelCount[label] +
                      newLabelsSingleCount[label];
                  } else {
                    acc[label] = newLabelsSingleCount[label];
                  }
                  return acc;
                }, {}),
              },
              multiLabelCount: {
                ...singleMultiCountUpdated.multiLabelCount,
                ...Object.keys(newLabelsMultiCount).reduce((acc, label) => {
                  if (singleMultiCountUpdated.multiLabelCount[label]) {
                    acc[label] =
                      singleMultiCountUpdated.multiLabelCount[label] +
                      newLabelsMultiCount[label];
                  } else {
                    acc[label] = newLabelsMultiCount[label];
                  }
                  return acc;
                }, {}),
              },
            };
          }
          if (!optionsCount) return EMPTY;

          return of({ optionsCount, roisToUpdate, mode }).pipe(
            map(({ optionsCount, roisToUpdate, mode }) => {
              return mode === Mode.REVIEW
                ? updateStatsOnReviewMode({
                    optionsCount,
                    roisToUpdate,
                    singleAndMultiCount: singleMultiCountUpdated,
                  })
                : updateStats({
                    optionsCount,
                    roisToUpdate,
                    singleAndMultiCount: singleMultiCountUpdated,
                    analysisStateId: analysisState?.id,
                  });
            }),
            catchError((error) =>
              of(
                sampleCounterActionError({
                  error: `[updateStatsOnSelectLabel$]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  updateLabelCountReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setReviewCounters),
      withLatestFrom(
        this.store$.select(selectActiveSample),
        this.store$.select(labelCountIds),
        this.store$.select(labelCountReviewIsEmpty)
      ),
      mergeMap(
        ([
          { newROIs, oldROIs, authUser },
          activeSample,
          labelCountIds,
          labelCountReviewIsEmpty,
        ]) => {
          const labelsToAdd = AnalysisUtils.flatten(
            newROIs.map((roi) =>
              roi.labels.reduce(
                (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
                []
              )
            )
          );
          const labelsToDelete = AnalysisUtils.flatten(
            oldROIs.map((roi) =>
              roi.labels.reduce(
                (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
                []
              )
            )
          );

          const newMultiSingleCount = extractSingleAndMultiCount(newROIs);
          const oldMultiSingleCount = labelCountReviewIsEmpty
            ? {}
            : makeCountNegative(extractSingleAndMultiCount(oldROIs));
          const multiSingleCount = updateSingleMultiCount(
            oldMultiSingleCount,
            newMultiSingleCount
          );

          const roisToUpdate = newROIs?.length - oldROIs?.length;
          const additionCount = getOptionsCount(labelsToAdd);

          const deletionCount = labelsToDelete?.reduce(function (prev, cur) {
            prev[cur] = (prev[cur] || 0) - 1;
            return prev;
          }, {});

          return this._analysisReviewService
            .getUserAnalysisState(activeSample)
            .pipe(
              switchMap((analysisStateId) =>
                of({
                  additionCount,
                  deletionCount,
                  roisToUpdate,
                  analysisStateId,
                  multiSingleCount,
                  requestLabelCount: !(labelCountIds as string[])?.includes(
                    analysisStateId
                  ),
                  activeSample,
                  authUser,
                })
              ),
              mergeMap(
                ({
                  additionCount,
                  deletionCount,
                  roisToUpdate,
                  analysisStateId,
                  multiSingleCount,
                  requestLabelCount,
                  activeSample,
                  authUser,
                }) => {
                  if (requestLabelCount) {
                    return defer(() =>
                      CloudFunctions.GetSampleAnalsysStats(
                        activeSample,
                        authUser
                      )
                    ).pipe(
                      switchMap((stats) =>
                        of({
                          additionCount,
                          deletionCount,
                          roisToUpdate,
                          analysisStateId,
                          multiSingleCount,
                          requestLabelCount,
                          stats,
                        })
                      )
                    );
                  }
                  return of({
                    additionCount,
                    deletionCount,
                    roisToUpdate,
                    analysisStateId,
                    multiSingleCount,
                    requestLabelCount,
                    stats: undefined,
                  });
                }
              ),
              mergeMap(
                ({
                  additionCount,
                  deletionCount,
                  roisToUpdate,
                  analysisStateId,
                  multiSingleCount,
                  stats,
                }) => {
                  return stats
                    ? [
                        loadStatsSuccess({
                          labelCount: stats.labelCount,
                          totalCount: stats.total,
                          multiLabelCount: stats.multiLabelCount,
                          singleLabelCount: stats.singleLabelCount,
                          analysisStateId,
                        }),
                        updateReviewCount({
                          additionCount,
                          deletionCount,
                          roisToUpdate,
                          analysisStateId,
                          multiSingleCount,
                        }),
                      ]
                    : [
                        updateReviewCount({
                          additionCount,
                          deletionCount,
                          roisToUpdate,
                          analysisStateId,
                          multiSingleCount,
                        }),
                      ];
                }
              ),
              catchError((error) => {
                return of(
                  sampleCounterActionError({
                    error: `[setReviewCounters]: ${error.message}`,
                  })
                );
              })
            );
        }
      )
    )
  );

  deleteRois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateROILabels),
      withLatestFrom(
        this.store$.select(selectSelectedROIS),
        this.store$.select(selectActiveAssetContextAnalysis)
      ),
      mergeMap(([{ label }, selectedRois, activeAnalysisDetail]) => {
        const labelAnalysis = (activeAnalysisDetail?.analysisArray ?? []).find(
          (a) => a.pipelineId === label.pipelineId
        );
        if (selectedRois.length === 0 && !labelAnalysis) return EMPTY;

        const roisToDelete = selectedRois.filter((roi) => {
          const roiLabels = roi.labels.reduce(
            (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
            []
          );
          return roiLabels.length === 0;
        });

        return of(
          removeROIs({
            rois: roisToDelete as ROI[],
          })
        );
      })
    )
  );

  deleteVideoRois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateROILabels),
      withLatestFrom(
        this.store$.select(selectSelectedVideoRois),
        this.store$.select(selectActiveAssetContextAnalysis)
      ),
      mergeMap(([{ label }, selectedRois, activeAnalysisDetail]) => {
        const labelAnalysis = (activeAnalysisDetail?.analysisArray ?? []).find(
          (a) => a.pipelineId === label.pipelineId
        );
        if (selectedRois.length === 0 && !labelAnalysis) return EMPTY;

        const roisToDelete = selectedRois.filter((roi) => {
          const roiLabels = Object.keys(roi.labels);
          return roiLabels.length === 0;
        });

        return of(
          removeVideoROIs({
            rois: roisToDelete,
          })
        );
      })
    )
  );

  updateCounters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateROI),
      withLatestFrom(this.store$.select(analysisState)),
      mergeMap(([{ roi, changes }, analysisState]) => {
        const optionsCount = {};

        const previousLabels = getUniqueLabels([roi]);
        const currentLabels = getUniqueLabels([changes]);

        const previousEmpty = Object.keys(previousLabels).length ? false : true;
        const previousMulti = previousEmpty
          ? false
          : Object.keys(previousLabels).length > 1
          ? true
          : false;
        const currentEmpty = Object.keys(currentLabels).length ? false : true;

        const roisToUpdate = previousEmpty
          ? currentEmpty
            ? 0
            : 1
          : previousMulti
          ? 0
          : currentEmpty
          ? -1
          : 0;

        for (const prevLabel of previousLabels) {
          const labelExists = currentLabels.includes(prevLabel);
          if (!labelExists) {
            optionsCount[prevLabel] = -1;
          }
        }

        for (const currLabel of currentLabels) {
          const labelIsNew = !previousLabels.includes(currLabel);

          if (labelIsNew) {
            optionsCount[currLabel] = 1;
          }
        }

        return of({ optionsCount, roisToUpdate }).pipe(
          map(({ optionsCount, roisToUpdate }) => {
            return updateStats({
              optionsCount,
              roisToUpdate,
              singleAndMultiCount: {
                singleLabelCount: optionsCount,
                multiLabelCount: {},
              },
              analysisStateId: analysisState?.id,
            });
          })
        );
      })
    )
  );

  clearReviewCounters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchMode),
      map(({ mode }) => {
        if (mode === Mode.REVIEW) return;
        return clearReviewCount();
      })
    )
  );

  replaceCounters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analysisSynced),
      map((_) => {
        return replaceCounter();
      })
    )
  );
}
