import type { ROI } from "../../state";

type Coords = Pick<ROI, "x" | "y" | "w" | "h">;

export class RoiMapper {
  public static getId(
    coords: Coords,
    creatorId: string,
    assetId: string
  ): string {
    const x = Math.round(coords.x);
    const y = Math.round(coords.y);
    const w = Math.round(coords.w);
    const h = Math.round(coords.h);

    const coordString = JSON.stringify({ x, y, w, h, creatorId, assetId });

    return btoa(coordString);
  }
}
