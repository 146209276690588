// REVIEW: convert to domain object

export interface MosaicRoi {
  id: string;
  label: string;
  score: number;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  creatorEntity: string;
  creatorId: string;
  assetId: string;
  sampleId: string;
  analysisId: string;
  findingId: string;
  findingVersionId: string;
  findingVersion: number;
  stepId: string;
  pipelineId: string;
}

export type PartialMosaicRoi = Omit<
  MosaicRoi,
  "score" | "id" | "label" | "filename"
>;

export class MosaicRois extends Array<MosaicRoi> {
  constructor(items: MosaicRoi[]) {
    super(...items);
  }

  public groupById() {
    const groupedItems = [];

    for (const item of this.values()) {
      const { label, score, ...noLabelRoi } = item;

      const existingRoi = groupedItems.findIndex((roi) => roi.id === item.id);

      if (existingRoi === -1) {
        groupedItems.push({ ...noLabelRoi, labels: { [label]: score } });
        continue;
      }

      groupedItems[existingRoi].labels[label] = score;
    }

    return groupedItems;
  }
}

export const containedIn = (array: MosaicRoi[]) => (r1: MosaicRoi) =>
  array.some((r2) => r2.id === r1.id && r2.label === r1.label);
export const notContainedIn = (array: MosaicRoi[]) => (r1: MosaicRoi) =>
  !array.some((r2) => r2.id === r1.id && r2.label === r1.label);

export function getRoisFrom(
  finding: any
): Omit<MosaicRoi, "id" | "filename">[] {
  const rois = [];

  for (const findingRoi of finding.data.content) {
    if (checkIfOutOfBounds(findingRoi)) continue;

    const labels = Object.entries(findingRoi.labels);

    const parcialRoi: PartialMosaicRoi = {
      assetId: finding.analysis.asset.objectId,
      sampleId: finding.analysis.sample.objectId,
      analysisId: finding.analysis.objectId,
      creatorEntity: finding.creatorEntity,
      creatorId: finding.creatorId,
      findingId: finding.uuid ?? finding.objectId,
      findingVersionId: finding.objectId,
      findingVersion: finding.version,
      stepId: finding.pipelineStep.objectId,
      pipelineId: finding.pipelineStep.pipeline.objectId,
      x0: Number(findingRoi.x0),
      x1: Number(findingRoi.x1),
      y0: Number(findingRoi.y0),
      y1: Number(findingRoi.y1),
    };

    const roisWithoutId = labels.map(([label, score]) => ({
      ...parcialRoi,
      label,
      score,
    }));

    rois.push(...roisWithoutId);
  }

  return rois;
}

export function checkIfROI(item: MosaicRoi): boolean {
  const { x0, x1, y0, y1 } = getCoordAsNum(item);

  return x0 !== x1 || y0 !== y1;
}

export function checkIfOutOfBounds(item: MosaicRoi) {
  const { x0, x1, y0, y1 } = getCoordAsNum(item);

  const isWithinBounds =
    isNormarilzed(x0) &&
    isNormarilzed(x1) &&
    isNormarilzed(y0) &&
    isNormarilzed(y1);

  return !isWithinBounds;
}

export function isNormarilzed(n: number) {
  return n >= 0 && n <= 1;
}

export function getCoordAsNum(item: MosaicRoi) {
  const x0 = Number(item.x0);
  const x1 = Number(item.x1);
  const y0 = Number(item.y0);
  const y1 = Number(item.y1);

  return { x0, x1, y0, y1 };
}
